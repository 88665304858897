import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/alirun/Work/Blockeys/ofi-landing/src/layouts/blogPost.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`July has been a lovely summer month for the Opium team and the Opium community, with some exciting developments happening, meeting our passionate builders face-to-face, as well as launching some interesting new products.`}</p>
    <p>{`Here’s a rundown of what we’ve been up to:`}</p>
    <h1 {...{
      "id": "product-updates",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#product-updates",
        "aria-label": "product updates permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Product Updates`}</h1>
    <h2 {...{
      "id": "staking-pool-performance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#staking-pool-performance",
        "aria-label": "staking pool performance permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Staking Pool Performance`}</h2>
    <p>{`Every Friday, we will now be posting our staking pool performance to keep users and interested parties up to date with our results. `}</p>
    <p>{`The ‘return since inception’ feature allows users to view the stakers total return on each pool from its inception date until the present date. `}</p>
    <p>{`This way, the Opium community, as well as new, prospective stakers will be able to see how our pools are performing and use the information to inform their investment decisions - but don’t forget, past performance is not a guarantee of future performance!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0433c43d91fabb7ef73262696d6cf7cb/71182/BestPoolPerformance.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAACxLAAAsSwGlPZapAAAB+0lEQVR42lWS63KbMBCF/QIdGwPmYgyIiwBzMQYDvtRJm7aZTDttpj/a93+T013ZmU5+aCStpKPv7O7MXG0RigPW3g7r9U7NQdjDDzo1bKeCbuQwzELNb+slDRnXcDYjdG+A4XbqbLYiwevDT3y8/sLx9J3WrxjHF1T1I6rmkxJdaBJL/b+YRvu5IfE49RDtXyzK3zCyl5ugaZVEeITr1opuRfv5IsGHeQxBpI7bwF038LwWxqrAXEshsxNCOrOsGraVw7ULmHp6J3RqtMMPFPkFNRHF6QRv3cKzd5iGZ9TVA0oaTfsZgbeHpeU4jM/ouq/Yllc0uyc6f0ScTOQiw0w3cyTpEVE0wrJLItyS6AWxOCNx9pBihE30TBe5ewj6KIwOyLKzIucUsDMWVIQGF8Vnyw3Zo4cUNGlt2BUiIuVC2eTColTYJMAAG0EFCzt1nyE4LZwqJejYLYb6D6J4UBa4woyuUxGG4ZvKVyqP6ixKRizmKcrzF2T1FZk8IS8uSMkhf8qFmzHyNnsiy5OqaEA/J+KKjTeiCifk8ZFoqI1ED+n1SAgglCPk9nK/32NDb/zkoOwrQRGc4PsduCeZziWbFlumnIUUX5EtbUlxh6tawvVbBKJXlpcUt6hXXe+Wzxn7XuryZtMo3jWwZmT3+L2Z73Fdfx9/u8/jHxfNGv5vwB+jAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "BestPoolPerformance",
            "title": "BestPoolPerformance",
            "src": "/static/0433c43d91fabb7ef73262696d6cf7cb/c1b63/BestPoolPerformance.png",
            "srcSet": ["/static/0433c43d91fabb7ef73262696d6cf7cb/5a46d/BestPoolPerformance.png 300w", "/static/0433c43d91fabb7ef73262696d6cf7cb/0a47e/BestPoolPerformance.png 600w", "/static/0433c43d91fabb7ef73262696d6cf7cb/c1b63/BestPoolPerformance.png 1200w", "/static/0433c43d91fabb7ef73262696d6cf7cb/d61c2/BestPoolPerformance.png 1800w", "/static/0433c43d91fabb7ef73262696d6cf7cb/97a96/BestPoolPerformance.png 2400w", "/static/0433c43d91fabb7ef73262696d6cf7cb/71182/BestPoolPerformance.png 4048w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "spacex-rideshare-insurance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#spacex-rideshare-insurance",
        "aria-label": "spacex rideshare insurance permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SpaceX Rideshare Insurance`}</h2>
    <p>{`Due to the successful landing of the Falcon 9 at Cape Canaveral launched by SpaceX - the first reusable rocket to land successfully - stakers of our SpaceX insurance pool have enjoyed some healthy returns, receiving 100% of staked nominal and premiums on the 8th of July. `}</p>
    <p><blockquote parentName="p" {...{
        "className": "twitter-tweet",
        "data-dnt": "true"
      }}><p parentName="blockquote" {...{
          "lang": "en",
          "dir": "ltr"
        }}>{`The SpaceX rocket landed without incident. `}<a parentName="p" {...{
            "href": "https://twitter.com/Opium_Network"
          }}>{`@Opium_Network`}</a>{`'s insurance market has resolved successfully, with payouts to those who sold insurance. This market used UMA's Optimistic Oracle for trustless, secure resolution.`}<br parentName="p"></br><br parentName="p"></br>{`What else would you like to see insurance offered for? `}<a parentName="p" {...{
            "href": "https://t.co/rNsBBQKLWA"
          }}>{`https://t.co/rNsBBQKLWA`}</a></p>{`— UMA 🥚 (@UMAprotocol) `}<a parentName="blockquote" {...{
          "href": "https://twitter.com/UMAprotocol/status/1410987506313531393"
        }}>{`July 2, 2021`}</a></blockquote></p>
    <p>{`Thanks, Elon!`}</p>
    <h2 {...{
      "id": "hopium-vs-copium-nft",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#hopium-vs-copium-nft",
        "aria-label": "hopium vs copium nft permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HOPIUM vs COPIUM NFT`}</h2>
    <p>{`One of the great idea of how to name Opium product - HOPIUM and COPIUM. Such ideas deserve NFT for sure! `}</p>
    <p><blockquote parentName="p" {...{
        "className": "twitter-tweet",
        "data-dnt": "true"
      }}><p parentName="blockquote" {...{
          "lang": "en",
          "dir": "ltr"
        }}>{`Opium should sell $10k ETH Call options and package them as `}<a parentName="p" {...{
            "href": "https://twitter.com/search?q=%24HOPIUM&src=ctag&ref_src=twsrc%5Etfw"
          }}>{`$HOPIUM`}</a></p>{`— 𝕯𝖆𝖓𝖌𝖊𝖗 (@safetyth1rd) `}<a parentName="blockquote" {...{
          "href": "https://twitter.com/safetyth1rd/status/1402238621231435778"
        }}>{`June 8, 2021`}</a></blockquote></p>
    <p><img parentName="p" {...{
        "src": "/47fec3382500cca47ac2bbea34bdaa64/HOPIUMvsCOPIUM.gif",
        "alt": "Check it out"
      }}></img></p>
    <h2 {...{
      "id": "nft-for-bug-requests",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#nft-for-bug-requests",
        "aria-label": "nft for bug requests permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`NFT for bug requests`}</h2>
    <p>{`We decided to start distributing NFT’s as a reward for your help on our bug reporting program on `}<a parentName="p" {...{
        "href": "https://app.opium.finance"
      }}>{`opium.finance`}</a></p>
    <p>{`People who participate in bug reporting will receive an awesome NFT for their efforts; all you need to do is use app.opium.finance and report to us any bugs you encounter. `}</p>
    <p>{`Check out the NFT here: `}<a parentName="p" {...{
        "href": "https://opensea.io/assets/0x888888888877a56b4b809bf14bb76d63eb208297/11"
      }}>{`Thank you! NFT`}</a></p>
    <h2 {...{
      "id": "opium-turbo-explained",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#opium-turbo-explained",
        "aria-label": "opium turbo explained permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Opium Turbo Explained`}</h2>
    <p>{`A community member and active contributor - Namaissur - created a fantastic explanation video informing users how the Opium Turbo financial product works.`}</p>
    <p>{`By allowing a highly-leveraged exposure to an asset, you can spend a little and stand to gain a lot with Opium Turbo. `}</p>
    <p>{`Check out the video here: `}</p>
    <p><iframe parentName="p" {...{
        "width": "100%",
        "height": 315,
        "src": "https://www.youtube-nocookie.com/embed/8EhmgXbPQ6s?rel=0&feature=emb_title",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
        "allowFullScreen": true
      }}></iframe></p>
    <p>{`Show our community member some love by liking, commenting, and sharing.`}</p>
    <h2 {...{
      "id": "special-nft",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#special-nft",
        "aria-label": "special nft permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Special NFT`}</h2>
    <p>{`To say a big thank you to Namaissur for his continued support and contribution to Opium Finance, we have created a unique NFT for him as a reward.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/41a004911e60dfc3cd22f831c60d104a/eea4a/NFTNamaissur.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQCA//EABcBAQEBAQAAAAAAAAAAAAAAAAEDAAL/2gAMAwEAAhADEAAAAYJqO9ZSNs8Nh1kD/8QAGhAAAQUBAAAAAAAAAAAAAAAAAQACAxESIf/aAAgBAQABBQImoVxccBE2nVpaOF//xAAVEQEBAAAAAAAAAAAAAAAAAAAgMf/aAAgBAwEBPwGD/8QAFREBAQAAAAAAAAAAAAAAAAAAEAH/2gAIAQIBAT8BIf/EABsQAAEEAwAAAAAAAAAAAAAAAAEAEBEhAjFB/9oACAEBAAY/AhI298COU0EWhv/EABwQAAIDAAMBAAAAAAAAAAAAAAABESExQVFxYf/aAAgBAQABPyGok4rUa/h4JUuqFHpolnQ6InkkgidNnCP/2gAMAwEAAgADAAAAEGAvQf/EABgRAQEAAwAAAAAAAAAAAAAAAAEAESEx/9oACAEDAQE/EBBvrYkm/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQAQEf/aAAgBAgEBPxBJpb//xAAcEAEBAQADAAMAAAAAAAAAAAABEQAhMWFBUXH/2gAIAQEAAT8QCHtWCh2rgjxK1+Rb5iwjF5eO8IidFJ6f3cNERwe58E+s1aMUHvKtFYQr1v/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "NFTNamaissur",
            "title": "NFTNamaissur",
            "src": "/static/41a004911e60dfc3cd22f831c60d104a/e5166/NFTNamaissur.jpg",
            "srcSet": ["/static/41a004911e60dfc3cd22f831c60d104a/f93b5/NFTNamaissur.jpg 300w", "/static/41a004911e60dfc3cd22f831c60d104a/b4294/NFTNamaissur.jpg 600w", "/static/41a004911e60dfc3cd22f831c60d104a/e5166/NFTNamaissur.jpg 1200w", "/static/41a004911e60dfc3cd22f831c60d104a/eea4a/NFTNamaissur.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Check it out here: `}<a parentName="p" {...{
        "href": "https://opensea.io/assets/0x888888888877a56b4b809bf14bb76d63eb208297/12"
      }}>{`https://opensea.io/assets/0x888888888877a56b4b809bf14bb76d63eb208297/12`}</a>{` `}</p>
    <h1 {...{
      "id": "event-updates",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#event-updates",
        "aria-label": "event updates permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Event Updates`}</h1>
    <h2 {...{
      "id": "first-real-conference-since-lockdown-ethcc-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#first-real-conference-since-lockdown-ethcc-4",
        "aria-label": "first real conference since lockdown ethcc 4 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`First Real Conference Since Lockdown! EthCC `}{`[4]`}</h2>
    <p>{`It was an absolute pleasure to meet all of the passionate builders and community members at the EthCC week in Paris, and after receiving such positive feedback and witnessing the enthusiasm of our people, we have gained a level of traction that should keep us going until the next conference!`}</p>
    <p>{`The 1inch X Skale boat party was certainly an event to remember and a productive one, too, with many of our brilliant community members sharing their ideas and aspirations for the project’s future.`}</p>
    <p>{`Not only this, but we got to witness the lighting of the Eiffel Tower as we started sailing, which was an extraordinary moment that we were lucky enough to share with you all. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bf4362778f3086747007e2cf1e5b35ec/6a068/skalex1inch.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "133.33333333333331%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMCBAUB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHK6x8URxUrGdLGtQph/8QAGxAAAgMBAQEAAAAAAAAAAAAAAQIAAxEhEjH/2gAIAQEAAQUCyZMmRxyehOWMaFUr90h7WLN//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFxEBAAMAAAAAAAAAAAAAAAAAEAExQf/aAAgBAgEBPwGbNP/EAB0QAAICAQUAAAAAAAAAAAAAAAABAhEQEiAhUXH/2gAIAQEABj8C2R8x2KOqjmaEWi2f/8QAHRABAQEBAAEFAAAAAAAAAAAAAQARITFBUWFxgf/aAAgBAQABPyEizz69mEzWJ26OYD582ohs++2Q/SyTD3lhMdtC65f/2gAMAwEAAgADAAAAEG/lzv/EABgRAQEBAQEAAAAAAAAAAAAAAAERABAh/9oACAEDAQE/EAo8SCaTzf/EABkRAQEBAAMAAAAAAAAAAAAAAAERABAhUf/aAAgBAgEBPxBQHvEoXFe9/8QAHRABAAMAAwADAAAAAAAAAAAAAQARITFRcUGB8f/aAAgBAQABPxDCGFKpTyazCAIfEfT9hwWiTbTFOpVcBwNOj1zN5FXdF/UMVRDORuJNecahP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "skalex1inch",
            "title": "skalex1inch",
            "src": "/static/bf4362778f3086747007e2cf1e5b35ec/6a068/skalex1inch.jpg",
            "srcSet": ["/static/bf4362778f3086747007e2cf1e5b35ec/f93b5/skalex1inch.jpg 300w", "/static/bf4362778f3086747007e2cf1e5b35ec/b4294/skalex1inch.jpg 600w", "/static/bf4362778f3086747007e2cf1e5b35ec/6a068/skalex1inch.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The investors of Rockway Fund, together with the 1inch team, made the evening additionally special by organizing a party on the rooftop of the Czech Embassy, where we got to share ideas with some great minds with the dazzling spectacle of the Eiffel Tower lit up in the background.   `}</p>
    <p><blockquote parentName="p" {...{
        "className": "twitter-tweet",
        "data-dnt": "true"
      }}><p parentName="blockquote" {...{
          "lang": "en",
          "dir": "ltr"
        }}>{`Thank you all for attending `}<a parentName="p" {...{
            "href": "https://twitter.com/hashtag/RBF?src=hash&ref_src=twsrc%5Etfw"
          }}>{`#RBF`}</a>{` & `}<a parentName="p" {...{
            "href": "https://twitter.com/1inch"
          }}>{`@1inch`}</a>{` rooftop party last Wednesday in Paris after `}<a parentName="p" {...{
            "href": "https://twitter.com/EthCC"
          }}>{`@EthCC`}</a>{`! Was great to hang out with our community. Cannot wait to see you all at the next event! `}<a parentName="p" {...{
            "href": "https://t.co/cC9crSbhs6"
          }}>{`pic.twitter.com/cC9crSbhs6`}</a></p>{`— RockawayX (@Rockaway_X) `}<a parentName="blockquote" {...{
          "href": "https://twitter.com/Rockaway_X/status/1421086730086723584"
        }}>{`July 30, 2021`}</a></blockquote></p>
    <p>{`A lot had happened since our last conference before the whole world went into lockdown, and now we are emerging from it with excitement, ready to face new possibilities on the horizon. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/51a0928acf7b69e08dbe064fb773e96f/eea4a/Team.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAVZX1zbDIf/EABoQAAMBAAMAAAAAAAAAAAAAAAABAgMREiH/2gAIAQEAAQUC8Y5jl5IVdyaVl5w3/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHRAAAgIBBQAAAAAAAAAAAAAAAAECERIhIjEycf/aAAgBAQAGPwLJi1o7RGJKFyN3Ph//xAAcEAADAAIDAQAAAAAAAAAAAAAAAREhMUFRccH/2gAIAQEAAT8hUtPdfJmGj2lH0g5naUGzzQnRYwcQf//aAAwDAQACAAMAAAAQlx//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFR/9oACAEDAQE/EHCLT//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EFf/xAAcEAEBAAIDAQEAAAAAAAAAAAABEQAhMXGxQYH/2gAIAQEAAT8QqcIQuppDWRCJCCbF8MVSvflkk5VjF5dZ8dhYnV1MQmYpFNfuf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Team",
            "title": "Team",
            "src": "/static/51a0928acf7b69e08dbe064fb773e96f/e5166/Team.jpg",
            "srcSet": ["/static/51a0928acf7b69e08dbe064fb773e96f/f93b5/Team.jpg 300w", "/static/51a0928acf7b69e08dbe064fb773e96f/b4294/Team.jpg 600w", "/static/51a0928acf7b69e08dbe064fb773e96f/e5166/Team.jpg 1200w", "/static/51a0928acf7b69e08dbe064fb773e96f/eea4a/Team.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1 {...{
      "id": "we-are-hiring",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#we-are-hiring",
        "aria-label": "we are hiring permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`We Are Hiring!`}</h1>
    <p>{`We are currently on the lookout for some talented people to join our team:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Executive assistant`}</strong>{`. More info `}<a parentName="p" {...{
            "href": "https://opium-team.notion.site/Executive-assistant-Careers-at-Opium-7a32c1961c224235a846f18af4a17c73"
          }}>{`here`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Technical manager`}</strong>{`. More info `}<a parentName="p" {...{
            "href": "https://opium-team.notion.site/Technical-manager-areers-at-Opium-ce17ed41c8744e2ca0c28679f6e18783"
          }}>{`here`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Chief Marketing Officer`}</strong>{`. More info `}<a parentName="p" {...{
            "href": "https://opium-team.notion.site/Chief-Marketing-Officer-areers-at-Opium-fc70d6dfb0724ea98f51a1817598a914"
          }}>{`here`}</a></p>
      </li>
    </ul>
    <p>{`If you are interested in joining the team of Opium contributors, don’t hesitate to get in touch with us on Telegram or send your requests to `}<a parentName="p" {...{
        "href": "mailto:hi@opium.team"
      }}>{`hi@opium.team`}</a></p>
    <h2 {...{
      "id": "looking-forward",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#looking-forward",
        "aria-label": "looking forward permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Looking Forward`}</h2>
    <p>{`We are preparing some really great products for you to look forward to.`}</p>
    <p>{`Stay tuned!`}</p>
    <p>{`If you have some ideas or requests relating to the improvement of the app.opium.finance interface, please share your thoughts here: `}<a parentName="p" {...{
        "href": "https://opium.canny.io/opium-finance-feature-request"
      }}>{`opium.canny.io`}</a></p>
    <h1 {...{
      "id": "about-opium",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#about-opium",
        "aria-label": "about opium permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`About Opium`}</h1>
    <p>{`Opium Protocol is a universal and robust DeFi protocol that allows for creating, settling, and trading decentralized derivatives.`}</p>
    <p><a parentName="p" {...{
        "href": "https://app.opium.finance/"
      }}><strong parentName="a">{`Try out Opium Finance`}</strong></a>{` or `}<a parentName="p" {...{
        "href": "https://docs.opium.network/"
      }}><strong parentName="a">{`Explore Opium Protocol.`}</strong></a></p>
    <p>{`Stay informed and follow `}<a parentName="p" {...{
        "href": "https://twitter.com/Opium_Network"
      }}><strong parentName="a">{`Opium.Team on Twitter`}</strong></a>{`.`}</p>
    <p>{`Did you know that you can subscribe to our `}<a parentName="p" {...{
        "href": "https://t.me/OpiumFinance"
      }}><strong parentName="a">{`News Channel`}</strong></a>{` to get more exciting news delivered to your morning coffee?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      